import React, { useEffect, useState } from "react";
import axios from "axios";

const CoachLeaveRequest = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const response = await axios.get( `${process.env.REACT_APP_BACKEND_URL}/api/getAllleave-requests`);
        setLeaveRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leave requests:", error);
        setLoading(false);
      }
    };

    fetchLeaveRequests();
  }, []);

  const updateStatus = async (id, status) => {
    try {
      const response = await axios.patch( `${process.env.REACT_APP_BACKEND_URL}/api/update-leave-requests/${id}`, { status });
      setLeaveRequests((prevRequests) =>
        prevRequests.map((request) =>
          request._id === id ? { ...request, status: response.data.leaveRequest.status } : request
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="max-w-7xl mx-auto p-4">
    <h1 className="text-2xl font-bold text-gray-800 mb-6">Leave Requests Dashboard</h1>
    <div className="overflow-x-auto">
      <table className="table-auto w-full border-collapse border border-gray-200 shadow-md rounded-md">
        <thead className="bg-gray-100">
          <tr>
            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-600">
              Date
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-600">
              Reason
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-600">
              Description
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-600">
              Status
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-600">
              Coach Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center text-sm font-semibold text-gray-600">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {leaveRequests.map((request, index) => (
            <tr
              key={request._id}
              className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                {new Date(request.date).toLocaleDateString()}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                {request.reason}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                {request.description}
              </td>
              <td
                className={`border border-gray-300 px-4 py-2 text-sm font-medium ${
                  request.status === "approved"
                    ? "text-green-600"
                    : request.status === "rejected"
                    ? "text-red-600"
                    : "text-yellow-600"
                }`}
              >
                {request ? request.status.charAt(0).toUpperCase() + request.status.slice(1): null}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                {request.coachId?.name || "N/A"}
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center text-black">
                {request.status === "pending" && (
                  <div className="flex justify-center space-x-2">
                    <button
                      onClick={() => updateStatus(request._id, "approved")}
                      className="px-3 py-1 bg-green-500  text-sm rounded-md hover:bg-green-600 focus:outline-none"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => updateStatus(request._id, "rejected")}
                      className="px-3 py-1 bg-red-500  text-sm rounded-md hover:bg-red-600 focus:outline-none"
                    >
                      Reject
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default CoachLeaveRequest;
