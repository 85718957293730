import React, { useState, useEffect } from "react";
import logo from "../../../assets/grpClassOrder/logo.png";
import { FaDiscord, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { paymentOptions } from "../../../utils/paymentHandler";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useRazorpay from "../../../Hooks/useRazorpay";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const WeekendClass = () => {
  useRazorpay();

  const navigate = useNavigate();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const transactionId = sessionStorage.getItem("payment_orderId");
  const [paymentForm, setPaymentForm] = useState({
    amount: 1200,
    studentName: "",
    studentClass: "",
    schoolName: "",
    email: "",
    phone: "",
    couponCode: "",
  });
  const [applyCoupon, setApplyCoupon] = useState({
    discount: 0,
    message: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setPaymentForm((state) => ({ ...state, [name]: value }));
  };

  const onClickApplyCoupon = (e) => {
    e.preventDefault();
    console.log("chal to rha hai nn..?");
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/affiliate/exist?code=${paymentForm.couponCode}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.isExist) {
          setApplyCoupon({
            message: "coupon applied!",
            discount: (res.data.discountOff / 100) * paymentForm.amount,
          });
        } else {
          setApplyCoupon({
            message: "coupon is invalid or expired!",
            discount: 0,
          });
        }
      })
      .catch((err) => {
        setApplyCoupon({ discount: 0, message: "" });
      });
  };
  useEffect(() => {
    const isPaymentCompleted = async () => {
      try {
        if (transactionId) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/payment/checkPayementWeek?transcationid=${transactionId}`
          );
          if (response.data.transcationPresent) {
            setPaymentSuccess(true);
          } else {
            setPaymentSuccess(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    isPaymentCompleted();
  }, [transactionId]);

  const onclickPay = () => {
    if (
      paymentForm.studentName !== "" &&
      paymentForm.studentClass !== "" &&
      paymentForm.schoolName !== "" &&
      paymentForm.email !== "" &&
      paymentForm.phone !== ""
    ) {
      paymentOptions("razorpay")(
        { ...paymentForm, type: "weekendClass" },
        () => {
          // Set payment success state to true
          navigate(); // Navigate to appropriate page after payment
        }
      );
    } else {
      Swal.fire("Fill the required data!");
    }
  };
  return (
    <div className="flex flex-col px-10 lg:px-72 py-12 font-poppins text-black gap-8">
      <Helmet>
        <title>PlayerOrbit</title>
        <meta name="description" content="" />
        <meta name="keywords" content="chess" />
      </Helmet>
      <div className="flex text-black items-center gap-4">
        <img
          className="w-[7%] p-1 bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded "
          src={logo}
          alt=""
        />
        <h1 className="text-xl font-normal ">PlayerOrbit Sports PVT LTD</h1>
      </div>
      <div className="flex flex-col lg:flex-row w-full">
        <div className="flex flex-col lg:w-1/2">
          <h1 className="text-xl font-semibold">PlayerOrbit Chess Camp</h1>
          <div className="flex px-3 py-[2px] bg-[#367CEB] w-[4%] my-4"></div>

          <div className="flex flex-col text-xs opacity-90 gap-6 my-4">
            <p>Prepare your child for a brighter future!</p>
            <div>
              <p>Start Learning Chess Today</p>
              <ul className="list-disc px-8 flex gap-3 flex-col my-3">
                <li>National & International Coaches</li>
                <li>Comprehensive chess Curriculum</li>
                <li>12 Tailored Sessions per Month</li>
                <li>Weekly Tournaments</li>
                <li>Online Classes</li>
              </ul>
            </div>
          </div>

          <div>
            <h1 className="flex font-semibold text-xs my-3">Share this on:</h1>
            <div className="flex gap-2 px-4">
              <FaDiscord />
              <FaInstagram />
              <FaLinkedin />
              <FaYoutube />
            </div>
          </div>
          <div>
            <h1 className="flex font-semibold text-xs my-3">Contact Us:</h1>
            <div className="flex flex-col justify-center md:pl-0 font-poppins gap-1">
              <div className="">
                <p className="text-base md:text-sm flex gap-2 items-center">
                  <span className="">
                    <FaPhoneAlt />
                  </span>
                  <a href="tel:+91 8830748916">8830748916</a>
                </p>
              </div>
              <div className="">
                <a
                  className="text-base md:text-sm flex  gap-2 items-center"
                  href="mailto:contact@playerorbit.com"
                  target="_top"
                >
                  <span className="text-xl">
                    <IoMailOutline />
                  </span>
                  contact@playerorbit.com
                </a>
              </div>
            </div>
          </div>
        </div>

        {transactionId && paymentSuccess ? (
          <div className="flex flex-col lg:w-1/2 bg-white text-black shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded relative overflow-hidden mt-10 lg:bottom-4">
            <h1 className="text-lg font-semibold text-center">
              Payment Successfully!
            </h1>
            <h1 className="text-lg font-semibold text-center">
              You will receive email shortly
            </h1>
            <h1 className="text-lg font-semibold text-center">
              Order ID:{transactionId}
            </h1>
          </div>
        ) : (
          <div className="flex flex-col lg:w-1/2 bg-white text-black shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 rounded relative overflow-hidden mt-10 lg:bottom-4">
            <h1 className="text-lg font-semibold ">Payment Details</h1>
            <div className="flex px-3 py-[2px] bg-[#367CEB] w-[4%] my-4"></div>
            <form className="flex flex-col justify-center items-center gap-4 lg:gap-8 py-4 text-sm lg:pt-8 pb-16">
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label className="w-1/3 font-light text-start ">Amount</label>
                <input
                  name="amount"
                  className="w-2/3 border px-4 py-1"
                  type="number"
                  value={1200}
                  disabled
                />
              </div>
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label className="w-1/3 font-light text-start ">
                  Student Name
                </label>
                <input
                  name="studentName"
                  className="w-2/3 border px-4 py-1"
                  type="text"
                  onChange={onChange}
                />
              </div>
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label className="w-1/3 font-light text-start ">
                  Student Class
                </label>
                <input
                  name="studentClass"
                  className="w-2/3 border px-4 py-1"
                  type="number"
                  onChange={onChange}
                />
              </div>
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label className="w-1/3 font-light text-start ">
                  School Name
                </label>
                <input
                  name="schoolName"
                  className="w-2/3 border px-4 py-1"
                  type="text"
                  onChange={onChange}
                />
              </div>
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label className="w-1/3 font-light text-start ">Email</label>
                <input
                  name="email"
                  className="w-2/3 border px-4 py-1"
                  type="email"
                  onChange={onChange}
                />
              </div>
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label className="w-1/3 font-light text-start">Phone</label>
                <input
                  name="phone"
                  className="w-2/3 border px-4 py-1"
                  type="number"
                  onChange={onChange}
                />
              </div>
              <div className="flex gap-4 justify-start w-full px-4 items-center">
                <label
                  htmlFor="couponCode"
                  className="w-1/3 font-light text-start"
                >
                  Coupon Code
                </label>
                {applyCoupon.message === "coupon applied!" ? (
                  <div className=" text-[#eb3636] col-span-2">
                    {applyCoupon.message}
                  </div>
                ) : (
                  <div className="w-2/3 border grid grid-cols-2 gap-1 ">
                    <input
                      name="couponCode"
                      className=" px-4 py-1 col-span-1"
                      type="text"
                      onChange={onChange}
                    />
                    <button
                      type="button"
                      className="bg-[#367CEB] text-white"
                      onClick={onClickApplyCoupon}
                    >
                      Apply
                    </button>
                    <div className=" text-[#eb3636] col-span-2">
                      {applyCoupon.message}
                    </div>
                  </div>
                )}
              </div>
            </form>
            <div className=" absolute bottom-0  right-0 ">
              <button
                className="px-12 py-4 bg-[#367CEB] text-white"
                onClick={onclickPay}
              >
                Pay Rs {paymentForm.amount - applyCoupon.discount}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeekendClass;
